import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import Grid from "@material-ui/core/Grid/Grid";
import Link from '../Link';
import ReactHtmlParser from "react-html-parser";
import styles from './styles';
import { node } from 'prop-types';

const useStyles = makeStyles(theme => styles(theme));

export default function Footer(props) {

  const classes = useStyles();
  const data = useStaticQuery(graphql`
      query FooterItemz {
        allWpMenu {
          edges {
            node {
              id
              name
              slug
              menuItems {
                nodes {
                  id
                  title
                  url
                  path
                  label
                  target
                  order
                  childItems {
                    nodes {
                      id
                      title
                      url
                      path
                      label
                      target
                      order
                    }
                  }
                }
              }
            }
          }
        }
      }
    `);

  function renderMenu(menu) {
    return <Grid item lg={3} xs={12} sm={6}>
      <div className="nav-column">
        {menu.map(item => {
          return <a href={item.url}>{item.label}</a>
        })};
      </div>
    </Grid>
  }

  const menus = data.allWpMenu.edges
  .filter(edge => edge.node.slug.includes('footer-col'))
  .map(menu => menu.node.menuItems.nodes)

  return (
    <div className={`footer-sitemap`}>
      <Grid container spacing={8}>
        {menus.map(menu => renderMenu(menu))}
      </Grid>
    </div>
  );
}