import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import Grid from "@material-ui/core/Grid/Grid";
import Link from '../Link';
import ReactHtmlParser from "react-html-parser";
import Button from '@material-ui/core/Button';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export default function TopNav(props) {
    const [open, setOpen] = React.useState(false);
    const handleFullMenuOpen = () => {
        setOpen(!open);
    }

    const classes = useStyles();

    const data = useStaticQuery(graphql`
      query TopNavItems {
        allWpMenu(filter: {locations: {in: HEADER_MENU}}) {
          edges {
            node {
              id
              name
              slug
              menuItems {
                nodes {
                  id
                  title
                  url
                  path
                  label
                  target
                  order
                  parentId
                  cssClasses
                  childItems {
                    nodes {
                      id
                      title
                      url
                      path
                      label
                      target
                      order
                    }
                  }
                }
              }
            }
          }
        }
        wp {
          themeOptions {
            ThemeOptions {
              siteLogo {
                sourceUrl
              }
            }
          }
        }
      }
    `);

  return (
      <React.Fragment>
          <div className={`top-nav  ${classes.topNav}  ${classes.topNavTheme}`}>
            <Grid container spacing={0} direction="row" alignItems="flex-start" style={{margin: 0, width: '100%'}}>
                <Grid item xs={6} md={3} >
                <div className="site-logo">
                    <Link to="/">
                        <img src={data.wp.themeOptions.ThemeOptions.siteLogo.sourceUrl}/>
                    </Link>
                </div>
                </Grid>
                <Grid item xs={6} md={12}>
                  <Button className="open-menu" onClick={handleFullMenuOpen} open={open}><span></span></Button>
                </Grid>
                <Grid item xs={12} md={9}>
                    <ul className="site-nav" open={open}>
                        {data.allWpMenu.edges[0].node.menuItems.nodes.map(section => (
                          <React.Fragment key={section.id}>
                            {section.parentId == null &&
                              <li className={section.childItems.nodes.length > 0 ? 'has-child' : '' }>
                                <Link
                                    className={section.cssClasses}
                                    to={section.path !== '##' ? section.path : null}
                                >
                                    {ReactHtmlParser(section.label)}
                                </Link>
                                {section.childItems.nodes.length > 0 &&
                                  <ul className="sub-site-nav">
                                    {section.childItems.nodes.map(item => (
                                      <li key={item.id} >
                                        <Link
                                            to={item.path}
                                        >
                                            {ReactHtmlParser(item.label)}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                }
                              </li>
                            }
                          </React.Fragment>
                        ))}
                    </ul>
                </Grid>
            </Grid>
          </div>
      </React.Fragment>
  );
}