import React from 'react';
import { makeStyles } from '@material-ui/styles';

import Form from './Form';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export default function SignUpModal({ showModal }) {
  const classes = useStyles();

  return <div className={classes.Modal}>
    <button onClick={() => showModal(false)}>x</button>
    <h3>Mailing list sign-up</h3>
    <p>Keep up-to-date with our latest news, events and Crowdfunder campaigns.</p>
    <Form classes={classes} showModal={showModal} />
  </div>;
}