import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import Grid from "@material-ui/core/Grid/Grid";
import Link from '../Link';
import ReactHtmlParser from "react-html-parser";
import styles from './styles';
import BackToTop from "react-back-to-top-button";

import Sitemap from './Sitemap';

import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';

import phoneIcon from '../../images/icon-phone.png';
import emailIcon from '../../images/icon-email.png';
import pinIcon from '../../images/icon-map-pin.png';
import logo1 from '../../images/Historic-Houses-logo_transparent-background_large.png';
import logo2 from '../../images/heritage-open-days-logo.png';
import logo3 from '../../images/burton-ghost-walks-logo_transparent-background_large.png';
import logo4 from '../../images/crowdfunder-logo.png';

const useStyles = makeStyles(theme => styles(theme));

export default function Footer(props) {

  const classes = useStyles();
  const data = useStaticQuery(graphql`
      query FooterItems {
        allWpMenu(filter: {locations: {in: FOOTER_MENU}}) {
          edges {
            node {
              id
              name
              slug
              menuItems {
                nodes {
                  id
                  title
                  url
                  path
                  label
                  target
                  order
                  childItems {
                    nodes {
                      id
                      title
                      url
                      path
                      label
                      target
                      order
                    }
                  }
                }
              }
            }
          }
        }
        wp {
          locationData {
            LocationData {
              address1
              address2
              email
              facebookHandle
              instagramHandle
              twitterHandle
              name
              postcode
              telephone
              region
              town
            }
          }
          themeOptions {
            ThemeOptions {
              footerLogo {
                sourceUrl
              }
            }
          }
        }
      }
    `);

  return (
    <div className={`footer ${classes.footer}  ${classes.footerTheme}`}>
      <Sitemap />
      <Grid container spacing={3} direction="row" alignItems="flex-start">
        <Grid item xs={12} md={6}>
            <p className="footer-h">Contact</p>
            <Grid container spacing={3} direction="row">
              <Grid item xs={12} lg={6}>
                <p>
                    {data.wp.locationData.LocationData.name !== null &&
                    <span>{data.wp.locationData.LocationData.name}, <br/></span>
                    }
                    {data.wp.locationData.LocationData.address1 !== null &&
                    <span>{data.wp.locationData.LocationData.address1}, <br/></span>
                    }
                    {data.wp.locationData.LocationData.address2 !== null &&
                    <span>{data.wp.locationData.LocationData.address2}, <br/></span>
                    }
                    {data.wp.locationData.LocationData.town !== null &&
                    <span>{data.wp.locationData.LocationData.town}, <br/></span>
                    }
                    {data.wp.locationData.LocationData.region !== null &&
                    <span>{data.wp.locationData.LocationData.region}, <br/></span>
                    }
                    {data.wp.locationData.LocationData.postcode !== null &&
                    <span>{data.wp.locationData.LocationData.postcode} </span>
                    }
                </p>
                <button onClick={() => props.showModal(true)} className="mailing">Mailing list sign up</button>
              </Grid>
              <Grid item xs={12} lg={6}>
                <p><Link to={'tel:' + data.wp.locationData.LocationData.telephone} ><img src={phoneIcon} /> {data.wp.locationData.LocationData.telephone}</Link></p>
                <p><Link to={'mailto:' + data.wp.locationData.LocationData.email} ><img src={emailIcon} /> {data.wp.locationData.LocationData.email}</Link></p>
                <p className="social-links">
                    {data.wp.locationData.LocationData.twitterHandle !== null &&
                        <Link to={"https://www.twitter.com/" + data.wp.locationData.LocationData.twitterHandle}><TwitterIcon/>Twitter</Link>
                    }
                    {data.wp.locationData.LocationData.facebookHandle !== null &&
                        <Link to={"https://www.facebook.com/" + data.wp.locationData.LocationData.facebookHandle}><FacebookIcon/>Facebook</Link>
                    }
                </p>
              </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} md={1} />
        <Grid item xs={12} md={5} className="partners">
            <p className="footer-h">Partners</p>
            <p>
                <Link to="https://www.historichouses.org/"><img src={logo1} alt="Historic Houses" /></Link>
                <Link to="https://www.heritageopendays.org.uk/"><img src={logo2} alt="Heritage open days" /></Link>
                <Link to="http://www.burtonghostandhistorywalk.co.uk/"><img src={logo3} alt="Burton Ghost Walk" /></Link>
                <Link to="https://www.crowdfunder.co.uk/"><img src={logo4} alt="Crowdfunder" /></Link>
            </p>
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
        <Grid item xs={12} md={3}>
          <div className="site-logo">
              <Link to="/">
                  <img src={data.wp.themeOptions.ThemeOptions.footerLogo.sourceUrl} alt={data.wp.locationData.LocationData.name}/>
              </Link>
          </div>
        </Grid>
        <Grid item xs={12} md={9}>
            <div className="footer-nav">
                {data.allWpMenu.edges[0].node.menuItems.nodes.map(section => (
                    <Link
                        key={section.path}
                        to={section.path}
                    >
                        {ReactHtmlParser(section.label)}
                    </Link>
                ))}
            </div>
        </Grid>
      </Grid>
      <BackToTop
        showAt={0}
        speed={600}
        easing="easeInOutQuint"
      >
        <span>Top</span>
      </BackToTop>
      <p className="site-credits">©2021 Sinai Park House · An extraordinary place · Charity Reg. No. 1172341 | <Link to="https://www.wearebrew.co.uk">Powered by Brew</Link></p>
    </div>
  );
}