import React, {useEffect, useState} from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { postData } from './actions';

import Status from './Status';
import {trackSubscribe} from '../../services/gtm';

export default function Form({classes}) {
    const [forename, setForename]                   = useState('');
    const [surname, setSurname]                     = useState('');
    const [email, setEmail]                         = useState('');
    const [title, setTitle]                         = useState('');
    const [status, setStatus]                       = useState({
        state: '',
        msg:   ''
    });
    const [validationErrors, setValidationErrors]   = useState({dob: ["You’ll need to be over 18 to subscribe"]});
    const [formError, setFormError]                 = useState([]);
    const [formSubmitted, setFormSubmitted]         = useState(false);
    const [formSending, setFormSending]             = useState(false);


    function handleSubmit(e) {
        e.preventDefault();

        let formData = {
            forename,
            surname,
            email,
            title
        };

        setStatus({
            state: 'pending',
            msg:   'We are processing your message'
        });

        setFormSending(true);

        postData(formData)
            .then(response => {
                if (Boolean(response.data.success)) {
                    trackSubscribe();
                    setFormSubmitted(true);
                }
                setFormSending(false);
            })
            .catch(error => {
                let errorMessage = 'There was a problem submitting your details. Please try again later.';
                if (error.hasOwnProperty('response')) {
                    if (error.response) {
                        console.log('Response', error.response);
                        if (error.response.data.hasOwnProperty('message')) {
                            setValidationErrors([]);
                            errorMessage = error.response.data.message;
                        } else if (error.response.data.hasOwnProperty('validation')) {
                            setValidationErrors(error.response.data.validation);
                            errorMessage = 'Please check the form for errors.';
                        }
                    } else if (error.request) {
                        console.log('Request', error.request);
                    } else {
                        console.log('Setup', error.message);
                    }
                }

                setFormError(errorMessage);
                setFormSending(false);
            });
    }

    return <div>
        {formSubmitted && <p className={classes.thanks}><CheckCircleIcon/> Thank you for subscribing.</p>}

        {!formSubmitted && formError.length != 0 &&
        <p className={classes.errors}><WarningIcon/> {formError}</p>
        }
        {!formSubmitted && <form className={classes.Form} onSubmit={handleSubmit}>
            <fieldset className={classes.Fields}>
            
                <Status status={status}/>

                <div className={classes.Field}>
                    <label htmlFor="title" className={classes.Label}>
                        Title
                    </label>
                    <select id="title" name="title"
                        required
                        className={classes.Input}
                        onChange={(e) => setTitle(e.target.value)}
                    >
                        <option selected disabled value="">--Please select--</option>
                        <option value="Mr">Mr</option>
                        <option value="Mrs">Mrs</option>
                        <option value="Ms">Ms</option>
                        <option value="Miss">Miss</option>
                    </select>
                </div>
                    
                <div className={classes.Field}>
                    <label htmlFor="forename" className={classes.Label}>
                        First Name
                    </label>
                    <input id="forename" name="forename" className={classes.Input} placeholder="your name"
                            required value={forename}
                            onChange={(e) => setForename(e.target.value)}/>
                    {validationErrors.forename &&
                    <p className={classes.validationError}>{validationErrors.forename}</p>}
                </div>

                <div className={classes.Field}>
                    <label htmlFor="surname" className={classes.Label}>
                        Last Name
                    </label>
                    <input id="surname" name="name" className={classes.Input} placeholder="your last name"
                            required value={surname}
                            onChange={(e) => setSurname(e.target.value)}/>
                    {validationErrors.surname &&
                    <p className={classes.validationError}>{validationErrors.surname}</p>}
                </div>
                
                <div className={classes.Field}>
                    <label htmlFor="email" className={classes.Label}>
                        Email Address
                    </label>
                    <input type="email" id="email" className={classes.Input} name="email"
                            placeholder="example@google.com" required value={email}
                            onChange={(e) => setEmail(e.target.value)}/>
                    {validationErrors.email &&
                    <p className={classes.validationError}>{validationErrors.email}</p>}
                </div>

                <p>
                    Marketing permissions
                </p>
                <p>
                    Please select <input type="checkbox" required /> email so you can hear from Sinai Park House.
                </p>
                <p>
                    You can unsubscribe at any time, by clicking the unsubscribe link at the bottom of our emails. Please read our privacy policy on how we will look after your information. We use Mailchimp as our marketing platform. By clicking below to subscribe, you acknowledge that your information will be transferred to Mailchimp for processing. Learn about Mailchimp's privacy practices here. This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.
                </p>


                <button type="submit" className={classes.Submit}>
                    Subscribe
                </button>
            </fieldset>
        </form>}
    </div>
};
