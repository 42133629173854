import BackTopTop from '../../images/back-to-top.png';
import linkArrow from '../../images/arrow-right-white.png';

export default theme => ({
    footer: {
        padding: '30px 0 0',
        position: 'relative',
        backgroundColor: theme.palette.primary.main,
        boxShadow: '0 500px 0 500px ' + theme.palette.primary.main,
        [theme.breakpoints.up('md')]: {
            paddingTop: 60,
        },
        '& .footer-sitemap': {
            '& .nav-column': {
                borderTop: '1px solid ' + theme.palette.primary.contrastText,
                paddingTop: 30,
                '& a': {
                    display: 'block',
                    marginBottom: 16,
                    fontSize: 16,
                    letterSpacing: '0.032em',
                    lineHeight: 1.5,
                    '&::after': {
                        content: '""',
                        width: 24,
                        height: 14,
                        backgroundImage: 'url(' + linkArrow + ')',
                        backgroundPosition: 'center right',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        display: 'inline-block',
                        marginLeft: 12,
                        transition: 'margin-left .3s ease-in-out',
                        verticalAlign: 'middle',
                    },
                    '&:hover, &:focus': {
                        '&::after': {
                            marginLeft: 18,
                        }
                    }
                }
            }
        },
        '& .footer-h': {
            fontSize: 18,
            fontWeight: 500,
            color: theme.palette.primary.contrastText,
            lineHeight: 1.8,
            marginTop: 0,
            marginBottom: 12,
            [theme.breakpoints.up('md')]: {
                fontSize: 24,
            },
        },
        '& p': {
            color: theme.palette.primary.contrastText,
            fontSize: 16,
            lineHeight: 1.5,
            letterSpacing: '0.032em',
        },
        '& a': {
            color: theme.palette.primary.contrastText,
            '& img': {
                display: 'inline-block',
                verticalAlign: 'middle',
                marginRight: 10,
            },
            '&:hover, &:focus': {
                opacity: 0.8,
            }
        },
        '& .social-links': {
            marginTop: 24,
            '& a': {
                display: 'inline-block',
                fontSize: 11,
                padding: '11px 15px',
                textTransform: 'uppercase',
                backgroundColor: theme.palette.primary.contrastText,
                color: theme.palette.primary.main,
                borderRadius: 8,
                lineHeight: '16px',
                marginRight: 24,
                '& svg': {
                    width: 16,
                    height: 16,
                    display: 'inline-block',
                    verticalAlign: 'text-bottom',
                    marginRight: 12,
                },
                '&:hover, &:focus': {
                    color: theme.palette.primary.dark,
                    backgroundColor: theme.palette.primary.contrastText,
                }
            }
        },
        '& .partners': {
            '& img': {
                margin: '0 32px 32px 0',
            }
        },
        '& .footer-nav': {
            [theme.breakpoints.up('md')]: {
                textAlign: 'right',
                margin: '22px 0',
            },
            '& a': {
                padding: '12px 0',
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: 12,
                letterSpacing: '0.152em',
                lineHeight: 1.25,
                display: 'block',
                [theme.breakpoints.up('md')]: {
                    display: 'inline-block',
                    paddingLeft: 22,
                    borderLeft: '2px solid ' + theme.palette.primary.contrastText,
                    marginLeft: 22,
                    paddingTop: 0,
                    paddingBottom: 0,
                    '&:nth-of-type(1)': {
                        paddingLeft: 0,
                        borderLeft: 'none',
                        marginLeft: 0,
                    }
                },
                [theme.breakpoints.up('lg')]: {
                    paddingLeft: 45,
                    marginLeft: 45,
                }
            }
        },
        '& .site-credits': {
            borderTop: '1px solid rgba(255,255,255,0.25)',
            marginTop: 32,
            width: '100vw',
            marginLeft: '50%',
            transform: 'translateX(-50%)',
            textAlign: 'center',
            color: theme.palette.primary.contrastText,
            padding: 18,
            lineHeight: 1.25,
            fontSize: 12,
            textTransform: 'uppercase',
            letterSpacing: '0.152em',
            marginBottom: 0,
            fontWeight: 500,
        },
        '& button.back-to-top': {
            position: 'absolute',
            top: -62,
            right: 0,
            bottom: 'auto',
            color: theme.palette.secondary.contrastText,
            fontSize: 16,
            fontWeight: 'bold',
            width: 93,
            height: 105,
            backgroundImage: 'url(' + BackTopTop + ')',
            backgroundPosition: 'top right',
            padding: '40px 0 28px 22px',
            textTransform: 'uppercase',
            letterSpacing: '0.152em',
            margin: 0,
        },
        '& .mailing': {
            display: 'inline-block',
            fontSize: 11,
            padding: '11px 15px',
            textTransform: 'uppercase',
            backgroundColor: theme.palette.primary.contrastText,
            color: theme.palette.primary.main,
            borderRadius: 8,
            lineHeight: '16px',
            marginRight: 24,
            border: 'none',
            '&:hover, &:focus': {
                color: theme.palette.primary.dark,
                backgroundColor: theme.palette.primary.contrastText,
                opacity: 0.8,
            }
        }
    },
    footerTheme: {
        ...theme.footerTheme,
    }
});