export default theme => ({
    Modal: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        zIndex: 10,
        backgroundColor: theme.palette.primary.main,
        width: '90%',
        maxWidth: 764,
        transform: 'translate(-50%, -50%)',
        boxShadow: '0 4px 6px rgb(0,0,0,0.5), 0 0 0 9000px rgba(255,255,255,0.75)',
        padding: 24,
        maxHeight: '95vh',
        overflowY: 'auto',
        [theme.breakpoints.up('md')]: {
            padding: 32,
        },
        '& > button': {
            backgroundColor: 'transparent',
            borderRadius: '50%',
            position: 'absolute',
            top: 12,
            right: 16,
            width: 32,
            height: 32,
            border: 'none',
            fontSize: 24,
            textAlign: 'center',
            lineHeight: '32px',
            padding: 0,
            color: theme.palette.primary.contrastText,
            fontFamily: 'Rubik',
        },
        '& h3': {
            fontSize: 24,
            color: theme.palette.primary.contrastText,
            marginBottom: 24,
            marginTop: 0,
            fontWeight: 'bold',
            textTransform: 'none',
            fontFamily: 'Piazzolla',
            [theme.breakpoints.up('md')]: {
                fontSize: 32,
            }
        },
        '& p': {
            fontFamily: 'Rubik',
            fontSize: 12,
            color: theme.palette.primary.contrastText,
            letterSpacing: '0.032em',
            lineHeight: 1.5,
        },
        '& form': {
            '& fieldset': {
                padding: 0,
                border: 'none',
            },
            '& label': {
                fontFamily: theme.bodyFont,
                letterSpacing: '0.152em',
                fontSize: 14,
                textTransform: 'uppercase',
                fontWeight: 500,
                lineHeight: '16px',
                marginBottom: 5,
                display: 'block',
                color: theme.palette.primary.contrastText,
            },
            '& input, & textarea, & select': {
                border: 'none',
                borderBottom: '2px solid white',
                padding: '6px 0',
                color: 'white',
                letterSpacing: '-0.02em',
                fontSize: 14,
                fontFamily: theme.bodyFont,
                backgroundColor: 'transparent !important',
                marginBottom: 24,
                display: 'block',
                width: '100%',
                outline: 'none',
                '& option': {
                    color: 'black',
                }
            },
            '& input[type="checkbox"]': {
                display: 'inline-block',
                width: 'auto',
                marginBottom: 0,
            },
            '& button': {
                fontFamily: theme.bodyFont,
                padding: '12px 22px 14px',
                boxShadow: '0 4px 6px rgba(0,0,0,0.5)',
                fontSize: 16,
                lineHeight: 1.2,
                fontWeight: 500,
                textAlign: 'center',
                minWidth: 185,
                border: 'none',
                outline: 'none',
                borderRadius: 4,
                textTransform: 'none',
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.primary.contrastText,
                display: 'flex',
                margin: '0 0 0 auto',
                justifyContent: 'center',
                transition: 'background-color .3s ease-in-out',
                '&:hover, &:focus': {
                    backgroundColor: theme.palette.secondary.light,
                }
            }
        }
    }
});