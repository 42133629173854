import dropDownAccent from '../../images/drop-nav-accent.png';

export default theme => ({
    topNav: {
        backgroundColor: theme.palette.primary.main,
        padding: 24,
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            padding: ' 28px 24px',
            '& .MuiGrid-grid-md-12': {
                display: 'none',
            }
        },
        '& *': {
            opacity: 1,
        },
        '&[open] *': {
            opacity: 0,
        },
        '&::after': {
            content: '""',
            display: 'table',
            clear: 'both',
        },
        '& .site-logo': {
            display: 'block',
            margin: 0,
            maxWidth: 120,
            marginBottom: 12,
            [theme.breakpoints.up('md')]: {
                maxWidth: 'none',
                marginBottom: 0,
            }
        },
        '& .site-nav': {
            display: 'block',
            width: 'calc(100% + 48px)',
            margin: '0 -24px',
            padding: 0,
            height: 0,
            opacity: 0,
            transition: 'opacity .3s ease-in-out',
            overflow: 'hidden',
            justifyContent: 'flex-end',
            backgroundColor: theme.palette.primary.main,
            '&[open]': {
                height: 'auto',
                opacity: 1,
                borderTop: '12px solid ' + theme.palette.primary.main,
                borderBottom: '12px solid ' + theme.palette.primary.main,
            },
            '& li': {
                display: 'block',
                position: 'relative',
                [theme.breakpoints.up('md')]: {
                    '&.has-child:hover, &.has-child:focus': {
                        '& > a': {
                            textDecoration: 'underline !important',
                        },
                        '& ul.sub-site-nav': {
                            height: 'auto',
                            opacity: 1,
                            overflow: 'visible',
                        }
                    }
                }
            },
            [theme.breakpoints.up('md')]: {
                float: 'right',
                height: 70,
                width: '100%',
                margin: '0 0',
                display: 'flex',
                backgroundColor: 'transparent',
                borderTop: 'none',
                borderBottom: 'none',
                transition: 'none',
                overflow: 'visible',
                alignItems: 'center',
                opacity: 1,
            },
            '& a': {
                fontSize: 16,
                color: theme.palette.background.secondary,
                fontFamily: theme.bodyFont,
                textDecoration: 'none',
                padding: '12px 24px',
                textTransform: 'uppercase',
                transition: 'opacity 250ms ease-in-out',
                opacity: 1,
                display: 'block',
                lineHeight: '1',
                textTransform: 'none',
                letterSpacing: '-0.016em',
                fontWeight: '500',
                [theme.breakpoints.up('md')]: {
                    fontSize: 20,
                    whiteSpace: 'nowrap',
                    '&.button': {
                        padding: '14px 32px',
                        borderRadius: 4,
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.primary.main,
                        marginLeft: 24,
                        boxShadow: '0 4px 6px rgba(0,0,0,0.25)',
                        transition: 'background-color .3s ease-in-out',
                        '&:hover, &:focus': {
                            opacity: 1,
                            backgroundColor: theme.palette.secondary.dark,
                        }
                    }
                },
                '&:hover, &:focus': {
                    opacity: '0.8',
                },
            },
            '& ul.sub-site-nav': {
                padding: 0,
                backgroundColor: theme.palette.background.secondary,
                [theme.breakpoints.up('md')]: {
                    position: 'absolute',
                    borderRadius: 6,
                    boxShadow: '0 4px 6px rgba(0,0,0,0.25)',
                    zIndex: 20,
                    top: '100%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    opacity: 0,
                    height: 0,
                    overflow: 'hidden',
                    transition: 'opacity .3s ease-in-out',
                    minWidth: 270,
                    '& a': {
                        padding: '30px 40px',
                    },
                    '&::before': {
                        content: '""',
                        backgroundImage: 'url(' + dropDownAccent + ')',
                        backgroundPosition: 'center top',
                        backgroundRepeat: 'no-repeat',
                        position: 'absolute',
                        bottom: '100%',
                        height: 8,
                        width: 20,
                        display: 'block',
                        left: 'calc(50% - 10px)',
                    }
                },
                '& a': {
                    color: theme.palette.primary.main,
                }
            }
        },
        '& .open-menu': {
            float: 'right',
            color: theme.palette.background.secondary,
            fontFamily: theme.bodyFont,
            fontSize: 16,
            letterSpacing: '0.068em',
            lineHeight: '20px',
            padding: 0,
            textTransform: 'none',
            fontWeight: '500',
            margin: '10px 0',
            textShadow: '0 4px 6px rgba(0,0,0,0.05)',
            '&::before': {
                content: '"Menu"',
            },
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
            '&[open]': {
                '&::before': {
                    content: '"Close"',
                }
            },
            '& .MuiButton-label': {
                '& span': {
                    display: 'block',
                    position: 'relative',
                    height: 3,
                    backgroundColor: theme.palette.background.secondary,
                    borderRadius: 2,
                    width: 25,
                    margin: '0 0 0 5px',
                    transition: 'width 250ms ease-in-out, margin-left 250ms ease-in-out',
                    boxShadow: '0 4px 6px rgba(0,0,0,0.25)',
                    [theme.breakpoints.up('lg')]: {
                        transform: 'scale(0.6)',
                        margin: '-4px 0 0 14px',
                    },
                    '&::after, &::before': {
                        content: '""',
                        backgroundColor: theme.palette.background.secondary,
                        position: 'absolute',
                        height: 3,
                        borderRadius: 2,
                        transition: 'width 250ms ease-in-out',
                        right: 0,
                        boxShadow: '0 4px 6px rgba(0,0,0,0.25)',
                    },
                    '&::after': {
                        width: 25,
                        top: 7,
                    },
                    '&::before': {
                        width: 25,
                        bottom: 7
                    }
                }
            },
            '&:hover, &:focus': {
                '& .MuiButton-label span': {
                    width: 25,
                    [theme.breakpoints.up('lg')]: {
                        marginLeft: 6,
                    },
                    '&::before, &::after': {
                        width: 25,
                    }
                }
            }
        },
    },
    topNavTheme: {
        ...theme.topNavTheme,
    }
});