import React from 'react';
import { Link as GatsbyLink } from "gatsby";
import { trackOutboundLink } from '../../services/gtm'

const Link = ({ children, to, activeClassName, forceBlank, ...other }) => {

    if(typeof(to)==="string" && !to.includes('.pdf')) {
        if (to) to = to.replace(process.env.GATSBY_ADMIN_URL, '/');
        const internal = /^\/(?!\/)/.test(to);

        // Clean some HTML elements
        to = to.split('&amp;').join('&');

        if (internal && forceBlank !== true) {
            // add a trailing slash
            // If there isnt one already and there isnt a hash present
            if (to.substr(-1) !== '/' && to.includes('#')===false && to.includes('?')===false) to += '/';
            return (
                <GatsbyLink to={to} activeClassName={activeClassName} {...other}>
                    {children}
                </GatsbyLink>
            )
        }
    }
    return (
        <a href={to} {...other} target="_blank" rel="noopener noreferrer" onClick={() => trackOutboundLink(to)}>
            {children}
        </a>
    )
};

export default Link
