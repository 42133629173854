import React, {Component} from "react";
import { Helmet } from "react-helmet";
import he from "he";
import data from "../../../.brew-cache/data.json";
import themeOptions from "../../../.brew-cache/theme.json";
import Apple57 from "../../images/favicon/apple-icon-57x57.png";
import Apple60 from "../../images/favicon/apple-icon-60x60.png";
import Apple72 from "../../images/favicon/apple-icon-72x72.png";
import Apple76 from "../../images/favicon/apple-icon-76x76.png";
import Apple114 from "../../images/favicon/apple-icon-114x114.png";
import Apple120 from "../../images/favicon/apple-icon-120x120.png";
import Apple144 from "../../images/favicon/apple-icon-144x144.png";
import Apple152 from "../../images/favicon/apple-icon-152x152.png";
import Apple180 from "../../images/favicon/apple-icon-180x180.png";
import Android190 from "../../images/favicon/android-icon-192x192.png";
import Fav32 from "../../images/favicon/favicon-32x32.png";
import Fav96 from "../../images/favicon/favicon-96x96.png";
import Fav16 from "../../images/favicon/favicon-16x16.png";
import Ms144 from "../../images/favicon/ms-icon-144x144.png";
import { replaceMasks } from "../../services/masks";

class Meta extends Component {
    
    defaultImage = themeOptions.ThemeOptions.defaultImages.schemaAndShareImage.sourceUrl.replace(process.env.GATSBY_ADMIN_URL, process.env.GATSBY_FRONTEND_URL);
        
    getSchema(){

        const schemaOrgJSONLD = [
            {
                "@context": "http://schema.org",
                "@type": "LocalBusiness",
                name: data.name,
                legalName: data.name,
                openingHours: data.opening_hours,
                geo: {
                    "@type": "GeoCoordinates",
                    latitude: data.latitude,
                    longitude: data.longitude,
                },
                description: "",
                slogan: "",
                address: {
                    "@type": "PostalAddress",
                    addressLocality: data.town,
                    addressRegion: data.region,
                    postalCode: data.postcode,
                    streetAddress: data.address1
                },
                telephone: data.telephone,
                email: data.email,
                image: this.defaultImage,
                url: data.url,
            }
        ];

        // Add any custom schema objects being passed through from the page
        if (this.props.meta.schema) {
            this.props.meta.schema.map(item => {
                return schemaOrgJSONLD.push(item);
            });
        }
        return JSON.stringify(schemaOrgJSONLD);
    }
    render() {
        const meta = replaceMasks(this.props.meta);
        
        return (
            <Helmet
                encodeSpecialCharacters={false}
                htmlAttributes={{ lang : meta.locale ? meta.locale : 'en' }}
            >
                <title>{meta.metaTitle ? he.decode(meta.metaTitle) : this.props.title}</title>
                <html lang="en" />
                <meta name="description" content={meta.metaDescription} />

                { meta.canonical !== undefined  ?  <link rel="canonical" href={data.url + meta.canonical} /> : null }

                <meta name="docsearch:version" content="2.0" />
                <meta
                    name="viewport"
                    content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
                />

                {/* Schema */}
                <script type="application/ld+json">
                    {this.getSchema(data.url)}
                </script>

                {/* OpenGraph */}
                { meta.ogTitle ? <meta property="og:url" content={data.url + this.props.path} /> : null }
                { meta.ogTitle ? <meta property="og:type" content="website" /> : null }
                { meta.ogTitle ? <meta property="og:locale" content="en" /> : null }
                { meta.ogTitle ? <meta property="og:site_name" content={data.name} /> : null }
                { meta.ogTitle ? <meta property="og:title" content={meta.ogTitle} /> : null }
                { meta.ogDescription  ? <meta property="og:description" content={meta.ogDescription} /> : null }
                { meta.ogImage  ? <meta property="og:image" content={data.url + meta.ogImage} /> : <meta property="og:image" content={this.defaultImage} /> }

                {/* Twitter Card */}
                { meta.twitterTitle ? <meta name="twitter:site" content={data.twitter_handle} /> : null }
                { meta.twitterTitle ? <meta name="twitter:title" content={meta.twitterTitle} /> : null }
                { meta.twitterTitle ? <meta name="twitter:description" content={meta.twitterDescription} /> : null }
                { meta.twitterTitle ? <meta name="twitter:card" content={meta.twitterCardType} /> : null }
                { meta.twitterImage ? <meta name="twitter:image" content={data.url + meta.twitterImage} /> : <meta name="twitter:image" content={this.defaultImage} /> }

                <link rel="apple-touch-icon" sizes="57x57" href={Apple57 } />
                <link rel="apple-touch-icon" sizes="60x60" href={Apple60} />
                <link rel="apple-touch-icon" sizes="72x72" href={Apple72} />
                <link rel="apple-touch-icon" sizes="76x76" href={Apple76} />
                <link rel="apple-touch-icon" sizes="114x114" href={Apple114} />
                <link rel="apple-touch-icon" sizes="120x120" href={Apple120} />
                <link rel="apple-touch-icon" sizes="144x144" href={Apple144} />
                <link rel="apple-touch-icon" sizes="152x152" href={Apple152} />
                <link rel="apple-touch-icon" sizes="180x180" href={Apple180} />
                <link rel="icon" type="image/png" sizes="192x192"  href={Android190} />
                <link rel="icon" type="image/png" sizes="32x32" href={Fav16} />
                <link rel="icon" type="image/png" sizes="96x96" href={Fav96} />
                <link rel="icon" type="image/png" sizes="16x16" href={Fav32} />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content={Ms144} />
                <meta name="theme-color" content="#ffffff" />
            </Helmet>
        )
    }
}

export default Meta
